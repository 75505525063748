.autoCode {
    width: 100%;
    display: flex;
    justify-content: space-between;
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }
  .autoCodeInput {
    outline: none;
    width: 66.5px;
    height: 63px;
    gap: 0px;
    border-radius: 8px;
    background: #F9F9F9;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: JDZhengHT-EN;
    font-size: 28px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    text-align: center;
  }
}
