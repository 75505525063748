.content__list {
  .content__list__title {
    color: #1a1a1a;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0 10px;
  }
  .content__list__footer {
    display: flex;
    align-items: center;
    cursor: pointer;
    .footer__text {
      font-size: 17px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #c2c4cc;
    }
    .email_text_black {
      color: #1a1a1a;
    }
    .email_text_unverified {
      color: #c2c4cc;
    }
    .footer__text__add {
      color: #ff0f23 !important;
    }
  }
}

.emailVerification {
  width: 100%;
  .form__box {
    width: 100%;
    .btn__box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 25px 0;
    }
  }
  .footer__box {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.addPhone {
  width: 100%;
  .input__box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .select__box {
      width: 23%;
    }
    .input {
      width: 76%;
    }
  }
  .error__text {
    font-size: 13px;
    color: #ff0f23;
    margin-left: 25%;
    margin-top: 5px;
  }
}

.phoneCheck {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .phoneCheckTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #888b94;

    .phoneEdit {
      color: #1869f5;
      margin: 0 10px;
      cursor: pointer;
    }
  }
  .phoneCheckContent {
    width: 100%;
    .error__text {
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #ff0f23;
      margin: 10px 0;
    }
  }
  .check__btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
  }
  .footer__text {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.verify__wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .flex__center {
    display: flex;
    align-items: center;
  }
  .Btn {
    width: 100%;
    margin: 15px 0;
  }
}

.accountSetting__wrap {
  width: 100%;
  height: 100vh;
  background-color: #f5f6fa;
}
.setPhoneLoading{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 18px auto;
  gap: 8px;
  color: #888b94;
  span {
    color: #888b94;
  }
}
.email__wrap {
  width: 100%;
  background-color: #fff;
  .emailTitle{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16px 0;
  }
  .title {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1a1a1a;
    margin-top: 36px;
  }
  .email__content {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1a1a1a;
    margin: 6px 0 16px 0;
  }
  .phone__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin: 35px 0 15px 0;
  }
  .email__text {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1a1a1a;
    margin-bottom: 16px;
  }
  .title__text {
    width: 100%;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #888b94;
    margin: 10px 0;
    text-align: center;
    .edit {
      color: #0866ff;
      margin: 0 6px;
      cursor: pointer;
    }
  }
  .ResendCode {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    color: #0866ff;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    cursor: pointer;
  }
  .ResendCode__time {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px;
    color: #888b94;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .error__text {
    //styleName: Small/12 Regular;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ff0f23;
    .red__close__icon {
      margin: 0 8px;
    }
  }
  .change__email {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 35px 0;
    gap: 6px;

    .email__text {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
    }
    .change__title__text {
      //styleName: Small/12 Regular;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #888b94;
    }
  }
  
  .btn_emailVerify_wrap{
    width: 100%;
    display: flex;
    // align-items: center;
    // flex-direction: column-reverse;
    align-items: flex-end;
    justify-content: space-between;
    align-content: center;
    flex-direction: row-reverse;
  }
  .unverified__email {
    width: 100%;
  }
  .btn__wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.change__password {
  padding: 16px 0;
  width: 100%;
  background-color: #fff;
  .inputLabel {
    color: #ff0f23;
  }
  .errorText {
    display: flex;
    align-items: center;
    margin: 6px 0;
  }
}

.success__wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  .success__content {
    margin: 36px 0;
    display: flex;
    align-items: center;
    span {
      //styleName: XLarge/18 Bold;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      color: #1a1a1a;
      margin: 0 8px;
    }
  }
}

.createPasswordWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px auto;
  padding: 16px 0;
  .title__text {
    width: calc(100% - 10px);
    padding: 0 5px;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #888b94;
  }

  .titleText {
    margin: 10px 0;
  }

  .contain {
    margin-left: 35px;
  }

  .InputBox {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin: 20px 0;
    width: 450px;
    .error__Text {
      display: flex;
      align-items: center;
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        margin: 16px 10px;
        color: #FF0F23;
      }
      .errorText{
        color: #269636;
      }
    }
    .errorPasswordBox {
      li {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
      }

      .successMsg {
        color: #269636;
      }

      .errorMsg {
        color: #ff0f23;
      }
    }
  }
}

.successBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px auto;
  padding: 80px 0;
  background-color: #fff;

  .successTitle {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    span {
      margin: 0 10px;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
    }
  }
}

.deleteAccount {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 12px;
  .deleteAccount_title{
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
  }
  .deleteAccount_conduct{
    width: 472px;
    font-size: 14px;
    line-height: 18px;
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: left;
    color: #888B94;
  }
  .deleteAccount_note{
    width: 472px;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    color: #888B94;
  }
  .deleteAccount_frame{
    width: 472px;
    margin-top: 8px;
    margin-bottom: 16px;
    padding: 8px 16px 8px 16px;
    border-radius: 4px;
    background: #F5F6FA;
  }
  .deleteAccount_detectTitle{
    font-size: 14px;
    line-height: 18px;
    text-align: left;
  }
  .deleteAccount_deltectContent{
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span{
      margin-left: 6px;
    }
  }
  .deleteAccount_contentRight{
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #2AA32A;
  }
  .deleteAccount_contentError{
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #FF0F23;
  }
  .deleteAccount_delete{
    width: 472px;
    height: 40px;
  }
}