.category-list-dropdown-layer {
  width: calc(100vw - 96px);
  min-width: 1008px;
  max-width: 1866px;
  position: absolute;
  top: 48px;
  left: 0;
  transform: translate(-150px, 0%);
  display: none;
  z-index: 2000;
  padding: 16px 16px 0px 16px;
  background: #FFF;
  border-radius: 8px;
  box-shadow: 0 6px 56px 0 #0000000A, 0 12px 35px 0 #0000000F;

  &::after {
    content: "";
    position: absolute;
    top: -20px;
    left: 0;
    width: 244px;
    height: 30px;
  }

  &:hover {
    display: block;
  }
}

.category-list-container {
  min-width: 928px;
  max-width: 1786px;
  width: calc(100vw - 128px);
  height: 620px;
  display: flex;
}

.category-divider {
  width: 1px;
  margin: 12px 16px;
  background-color: #F0F1F2;
}

.category-list-lv1 {
  width: 260px;
  overflow-y: auto;
  /* 对于Firefox浏览器 */
  scrollbar-width: none;
  /* 对于IE和Edge浏览器 */
  -ms-overflow-style: none;
  overscroll-behavior: none;

  .category-list-item {
    span {
      height: 36px;
      padding: 8px;
      display: flex;
      align-items: center;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      line-height: 20px;
      color: #1A1A1A;
      transition: 0.4s;
      cursor: pointer;
    }

    &.active span {
      color: var(--sys-color-Primary700);
      background-color: #ffe7e980;
    }

    &:hover span {
      color: var(--sys-color-Primary700);
      background-color: #ffe7e980;
    }
  }
}

.category-list-lv2 {
  width: 260px;
  overflow-y: auto;
  /* 对于Firefox浏览器 */
  scrollbar-width: none;
  /* 对于IE和Edge浏览器 */
  -ms-overflow-style: none;
  overscroll-behavior: none;

  .category-list-item {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
      border: 1px solid var(--sys-color-Primary700);
    }

    &:hover .category-list-item-cateName {
      color: var(--sys-color-Primary700);
    }

    .category-list-item-img {
      width: 60px;
      height: 60px;
      margin-right: 4px;
      border-radius: 4px;
      overflow: hidden;
      background-color: rgba(0, 0, 0, 0.02);
      object-fit: contain;

      img {
        width: 100%;
        object-fit: contain;
      }
    }

    .category-list-item-cateName {
      flex: 1;
      padding: 0 11px;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #1A1A1A;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.category-list-lv3 {
  width: 616px;
  flex: 1;
  overflow-y: auto;
  /* 对于Firefox浏览器 */
  scrollbar-width: none;
  /* 对于IE和Edge浏览器 */
  -ms-overflow-style: none;
  overscroll-behavior: none;
}


.category-list-lv3-li {
  .category-list-lv2-title {
    padding: 12px 0;
    display: flex;
    justify-content: space-between;

    .lv2-title {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #1A1A1A;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .view-all {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #888B94;
      cursor: pointer;
      flex-shrink: 0;
    }
  }
}

.ArrowRight {
  width: 10px;
  height: 10px;
  color: #888B94;
}

.category-list-lv3-items {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.category-list-lv3-item {
  width: 92px;
  height: 130px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;

  &:hover {
    border: 1px solid var(--sys-color-Primary700);
  }

  &:hover .category-list-lv3-items-name {
    color: var(--sys-color-Primary700);

  }
}

.category-list-lv3-items-img {
  width: 82px;
  height: 82px;
  font-size: 0px;
  margin: 6px 5px 4px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.02);
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.category-list-lv3-items-name {
  margin: 0 4px 6px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1A1A1A;
  word-wrap: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.category-list-right {
  width: 616px;
  flex: 1;
  overflow-y: auto;
  /* 对于Firefox浏览器 */
  scrollbar-width: none;
  /* 对于IE和Edge浏览器 */
  -ms-overflow-style: none;
  overscroll-behavior: none;
}