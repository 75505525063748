.dialogButton {
  width: 70px;
  height: 32px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  color: #FF0F23;
  border: 0.5px solid #E0E1E5;
}

.twoButtonDialogButton {
  width: 70px;
  height: 32px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 0.5px solid #E0E1E5;

  &:first-child {
    color: #1A1A1A;
  }

  &:last-child {
    color: #FFFFFF;
    background-color: #CC0C1C;
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  gap: 12px;
  right: 24px;
  bottom: 24px;
}

.textContainer {
  padding: 24px;
  font-size: 16px;
  line-height: 20px;
}