.loginDefault {
  width: 100%;
  height: 540px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  // 头部样式
  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
    .header_title {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        //styleName: Base/14 Regular;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #888b94;
        margin: 6px;
      }
    }
  }
  // 输入框内容
  .login__form {
    width: 380px;
    margin: 30px 0 20px 0;
    .emailListStyle{
      position: absolute;
      top: 55px;
      left: 0;
      width: 100%;
      height: 160px;
      background-color: #fff;
      z-index: 200;
      border-radius: 6px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      overflow-y: scroll;
      overflow-x: hidden;
      webkit-overflow-scrolling: touch; 
      &::-webkit-scrollbar {
        width: 6px;
        border-radius: 6px;
        background-color: #F5F5F5;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #E0E0E0;
        border-radius: 6px;
      }
      .item{
        cursor: pointer;
        margin: 9px 12px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #1a1a1a;
        &:hover {
          background-color: #F5F5F5;
        }
        white-space: nowrap;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #F0F1F2;
        .spanText{
          color: #888B94;
          display: inline-block;
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .emText{
          font-style: normal;
        }
      }
    }
    .select__box {
      width: 30%;
      height: 55px;
      position: relative;
      z-index: 5;
    }
  }
  .orJoinWith__ThirdParty {
    display: flex;
    flex-direction: column;
    align-items: center;
    .orJoinWith {
      display: flex;
      align-items: center;
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #888b94;
        margin: 0 8px;
      }
    }
    .loginThirdParty_btn {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: 23px 0;
      gap: 21px;
      .loginThirdParty_btn_item {
        cursor: pointer;
        width: 100px;
        height: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          font-size: 14px;
          line-height: 18px;
          margin: 12px 0;
          color: #1a1a1a;
        }
      }
    }
  }

  // 底部样式
  .footer__box {
    position: absolute;
    bottom: -20px;
    left: -24px;
    width: (calc(100% + 48px));
    .agreement {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #888b94;
        margin: 0;
        span {
          color: #1869f5;
          margin: 0 5px;
          cursor: pointer;
        }
      }
    }
    .mind {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background: linear-gradient(180deg, #ffffff 0%, #f0fdf2 100%);
      .mind__item {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        img{
          width: 20px;
          height: 20px;
          object-fit: contain;
        }
        span {
          color: #1a1a1a;
          margin: 0 6px;
        }
        em {
          margin: 0 6px;
          font-size: 13px;
          color: #888b94;
          font-style: normal;
        }
      }
    }
  }
  .footer__page__wrap {
    position: relative;
    bottom: 0;
    left: 0;
    .agreement {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #888b94;
        margin: 0;
        span {
          color: #1869f5;
          margin: 0 5px;
          cursor: pointer;
        }
      }
    }
    .mind {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background: linear-gradient(180deg, #ffffff 0%, #f0fdf2 100%);
      .mind__item {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        img{
          width: 20px;
          height: 20px;
          object-fit: contain;
        }
        span {
          color: #1a1a1a;
          margin: 0 6px;
        }
        em {
          margin: 0 6px;
          font-size: 13px;
          color: #888b94;
          font-style: normal;
        }
      }
    }
  }
}
.phoneEmailLogin {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  .title__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    .title {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #1a1a1a;
    }
    .title__text {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #888b94;
      line-height: 25px;
    }
  }
  .verificationCode__box {
    margin-top: 50px;
    .code__title {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #888b94;
      .edit {
        color: #0866ff;
        cursor: pointer;
        margin: 0 10px;
      }
    }
    .code__input {
      width: 100%;
    }
    .receive_the_code {
      width: 100%;
      text-align: left;
      line-height: 35px;
      color: #0866ff;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }
    .ResendCode {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 25px;
      color: #0866ff;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      cursor: pointer;
    }
    .ResendCode__time {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 25px;
      color: #888b94;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }
  .email__box {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    .errorPasswordBox {
      li {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
      }
      .successMsg {
        color: #269636;
      }
      .errorMsg {
        color: #CC0C1C;
      }
    }
    .errorText {
      display: flex;
      align-items: center;
      span {
        color: #CC0C1C;
        margin: 0 5px;
      }
    }
    .successText {
      display: flex;
      align-items: center;
      margin: 5px 0;
      span {
        color: #269636;
        margin: 0 5px;
      }
    }
  }
  .resetPsd {
    width: 100%;
    display: flex;
    justify-content: right;
    span {
      cursor: pointer; //styleName: Base/14 Regular;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #0866ff;
    }
  }
}
.loginDefault {
  width: 100%;
  height: 540px;
  .account__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #888b94;
    }
    .account__text {
      font-size: 20px;
      font-weight: 400;
      line-height: 20px;
      color: #1a1a1a;
      margin: 16px 0;
    }
  }
  .old__btn {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 55px 0;
  }
  .remove__account {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #888b94;
    margin: 22px 0;
    cursor: pointer;
  }
}
.mind__wrap {
  width: 100%;
  height: calc(100% - 20px);
  position: relative;
  .mind__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    .title {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #1a1a1a;
    }
    .text {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #888b94;
    }
  }
  .main__content {
    width: 100%;
    // height: 112px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    // background: linear-gradient(180deg, #fff3f4 0%, #fff7f8 100%);
    margin: 20px 0 10px 0;
    border-radius: 8px;
    overflow: hidden;
    .main__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 72px;
        height: 72px;
      }
      span {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #1a1a1a;
      }
      em {
        font-style: normal;
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #888b94;
      }
    }
  }
  .main__footer {
    width: 100%;
    // position: absolute;
    // bottom: -20px;
    // left: 0;
    display: flex;
    justify-content: right;
    align-items: center;
  }
}
.forget__wrap {
  width: 100%;
  height: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  .title__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }
  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1a1a1a;
  }
  .title__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #888b94;
    line-height: 25px;
    text-align: center;
    span {
      color: #1a1a1a;
    }
  }
  .send__box {
    width: 400px;
    margin-top: 50px;
    .errorText {
      display: flex;
      align-items: center;
      span {
        color: #CC0C1C;
        margin: 0 5px;
      }
    }
    .list {
      width: 100%;
      font-size: 14px;
      line-height: 18px;
      p {
        margin: 0;
        display: flex;
        // align-items: center;
        .list__item__text {
          margin: 0 5px;
        }
        .try_again {
          color: #1869f5;
          cursor: pointer;
        }
      }
    }
  }
}
.receiveTheCode_wrap {
  .title {
    width: 100%;
    color: #1a1a1a;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin-top: 16px;
    text-align: left;
  }
  .list {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    p {
      width: 500px;
      margin: 6px 0;
      white-space: pre-wrap;
    }
    .text{
      margin-bottom: 18px;
    }
    .identifier{
      font-weight: bold;
      font-size: 16px;
    }
  }
  .footer{
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
    margin: 15px 0 0 0;
  }
}
